<template>
  <v-row>
    <snack-bar />

    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center backgroundImg"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img src="@/assets/images/logos/logo-vumetric-telus-white.svg" alt="Vumetric logo">
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="mt-4 text-capitalize"
                  x-large
                  outlined
                  color="white"
                >
                  Learn More
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <v-btn
                class="mb-6"
                fab
                elevation="0"
                @click="backPage"
              >
                <v-icon x-large>mdi-keyboard-backspace</v-icon>
              </v-btn>
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                Reset your password
              </h2>
              <h6 class="subtitle-1">Enter your email address</h6>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  class="mt-4"
                  :rules="[rules.email]"
                  required
                  outlined
                />

                <v-btn
                  :disabled="!valid"
                  color="primary"
                  block
                  class="mr-4"
                  @click="resetPassword"
                >
                  Reset
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from '@/services/auth.service'
import snackbarPlugin from '@/plugins/snackbar'
import SnackBar from '@/components/base/SnackBar'

export default {
  name: 'Forgot',
  metaInfo: {
    title: 'Forgot Password'
  },
  components: {
    SnackBar
  },

  data: () => ({
    valid: false,
    email: null,
    rules: {
      required: (value) => !!value || 'Required.',
      counter: (value) => value.length <= 20 || 'Max 20 characters',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    }
  }),
  methods: {
    async resetPassword() {
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Please enter a valid email !')
      }
      if (this.$refs.form.validate()) {
        const res = await AuthService.passwordReset({ email: this.email })
        if (res.status !== 400) {
          this.$router.push('/login').then(() => {
            snackbarPlugin.showSuccess(`Email sent to ${this.email}!`)
          })
        }
      }
    },
    backPage() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">
.backgroundImg {
  background-image: url('../../assets/images/background-hex.png');
}
</style>
